import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ServiceState from "./Services/state";
import GuestBookPage from "./Pages/GuestBook";
import OptionPage from "./Pages/Option";
import InquiryPage from "./Pages/Inquiry";
import ECatalogPage from "./Pages/ECatalog";
import { TranslatorProvider, useTranslate } from "react-translate";
import EventPage from "./Pages/Event";
import ECatalogDetailPage from "./Pages/ECatalogDetail";
import DashboardPage from "./Pages/Dashboard";
import CompanyProfilePage from "./Pages/CompanyProfile";
import CompanyProfileDetailPage from "./Pages/CompanyProfileDetail";

function App() {
  let translations = {
    locale: "en",
    en: {
      guestbook: "Guestbook",
      guestbook_desc:
        "Please fill in the guest book to view the e-catalog and fill out an inquiry",
      nama: "Full Name",
      no_telepon: "Phone Number",
      instansi: "Institution / Hospital",
      bagian: "Job Position / Department",
      masuk: "Login",
      inquiry: "If any questions?",
      compro: "Company Profile",
      compro_desc: "View company profile",
      brosur: "View brochure",
      submit_inquiry: "The request has been sent successfully",
      submit: "Successfully filled out the guestbook",
      inquiry_desc:
        "If you have any questions, please fill in the following fields",
      inquiry_desc2:
        "Apakah dokter sudah pernah mendapatkan penjelasan mengenai TAKIPRIL (Prilocaine Hyperbaric 2%)?",
      inquiry_desc3:
        "Pada penggunaan Pump, apakah dokter pernah mendapatkan penjelasan dan informasi terkait manfaat penggunaanya pada TCI untuk anak dan pasien Obesitas?",
      submit_btn: "Submit",
      question: "Question",
      tutorial: "Tutorial",
      select_category: "Select Pertanyaan",
      list_question: "List Question",
    },
    id: {
      guestbook: "Buku Tamu",
      guestbook_desc:
        "Silahkan isi buku tamu untuk melihat e-catalog dan mengisi inquiry",
      nama: "Nama Lengkap",
      no_telepon: "Nomor Handphone",
      instansi: "Instansi / RS",
      bagian: "Posisi / Bagian",
      masuk: "Masuk",
      inquiry: "Jika ada pertanyaan?",
      compro: "Profil Perusahaan",
      compro_desc: "Lihat profil perusahaan",
      brosur: "Lihat brosur",
      submit_inquiry: "Inquiry berhasil dikirim",
      submit: "Sukses mengisi buku tamu",
      inquiry_desc:
        "Jika ada yang ingin ditanyakan, mohon diisi pada kolom berikut",
      inquiry_desc2:
        "Apakah dokter sudah pernah mendapatkan penjelasan mengenai TAKIPRIL (Prilocaine Hyperbaric 2%)?",
      inquiry_desc3:
        "Pada penggunaan Pump, apakah dokter pernah mendapatkan penjelasan dan informasi terkait manfaat penggunaanya pada TCI untuk anak dan pasien Obesitas?",
      submit_btn: "Kirim Pertanyaan",
      question: "Pertanyaan",
      tutorial: "Tutorial",
      select_category: "Pilih Pertanyaan",
      list_question: "List Pertanyaan",
    },
  };

  const [checked, setChecked] = useState(true);

  return (
    <ServiceState>
      <TranslatorProvider translations={translations}>
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <GuestBookPage checked={checked} setChecked={setChecked} />
              }
            />
            {/* <Route
              exact
              path="/event"
              element={<EventPage checked={checked} />}
            /> */}
            <Route
              exact
              path="/option"
              element={<OptionPage checked={checked} />}
            />
            <Route
              exact
              path="/inquiry"
              element={<InquiryPage checked={checked} />}
            />
            <Route
              exact
              path="/e-catalog"
              element={<ECatalogPage checked={checked} />}
            />
            <Route
              exact
              path="/e-catalog/detail/:id"
              element={<ECatalogDetailPage checked={checked} />}
            />
            <Route
              exact
              path="/company-profile"
              element={<CompanyProfilePage checked={checked} />}
            />
            <Route
              exact
              path="/company-profile/detail/:id"
              element={<CompanyProfileDetailPage checked={checked} />}
            />
            <Route exact path="/dashboard-data" element={<DashboardPage />} />
          </Routes>
        </Router>
        <ToastContainer />
      </TranslatorProvider>
    </ServiceState>
  );
}

export default App;
